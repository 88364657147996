<template>
  <section>
    <div v-if="blogList.length" class="column is-12">
      <div class="is-mobile">
        <div
          class="has-text-centered is-narrow is-size-3 pl-0 has-text-black"
          :class="screen === 'mobile' ? 'is-8' : 'is-12'"
        >
          {{ $t('Blog') }}
        </div>
      </div>
    </div>
    <div
      v-if="!loading && blogList.length"
      class="columns is-flex content-center is-mobile is-centered-mobile has-margin-5-mobile is-multiline mx-0"
    >
      <div
        v-for="(blog, index) in blogList"
        :key="`blog-${index}`"
        class="column is-mobile is-12-mobile pointer has-margin-bottom-10-mobile is-3-desktop transition-05s"
        @click="openDetailPage(blog.slug)"
      >
        <div class="outline rounded-10 overflow-hidden">
          <template>
            <div class="is-paddingless is-shadowless">
              <img
                class="blog-img-cover pointer"
                :src="get(blog, 'image')"
                style="object-fit: cover; height: 160px; width: 100%"
              />
              <div :title="blog.title" class="has-text-weight-bold pa-2">
                <p>{{ $options.truncateChars(blog.title, 25) }}</p>
                <span class="has-text-grey-light is-size-7">
                  {{ getHumanizeDate(get(blog, 'created_at')) }}&nbsp; - &nbsp;
                  {{ get(blog, 'category.name') }}</span
                >
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-if="blogList.length" class="is-narrow pr-0 has-text-centered">
      <span
        id="btn-blog-view-all"
        class="has-text-weight-bold text-orange pointer is-size-6"
        @click="gotoBlogList()"
      >
        {{ $t('View All') }}
      </span>
    </div>
  </section>
</template>
<script>
import { truncateChars } from '@/helpers/utils/common'
import { months } from '@/helpers/constants/common'
import { mapGetters } from 'vuex'
export default {
  truncateChars,
  props: {
    blogList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      screen: 'getScreen'
    })
  },
  methods: {
    gotoBlogList() {
      this.$router.push(
        this.localePath({
          name: 'blog'
        })
      )
    },

    getHumanizeDate(date) {
      const dates = new Date(date)
      const day = dates.getDate()
      const month = dates.getMonth()
      const year = dates.getFullYear()
      return `${day} ${months[month].slice(0, 3)} ${year}`
    },

    openDetailPage(slug) {
      this.$router.push(
        this.localePath({
          name: 'blog-detail-slug',
          params: { slug }
        })
      )
    }
  }
}
</script>
<style scoped>
.has-min-height-100 {
  min-height: 100px;
}
.has-opacity-half {
  opacity: 0.5;
}
</style>
