<template>
  <div class="columns has-text-centered">
    <div v-if="!loading" class="has-margin-auto">
      <vue-icon-base
        height="64"
        width="64"
        icon-color="grey"
        :path="$options.icons[$kc('uni-cloud-info')]"
      >
      </vue-icon-base>
      <div class="has-text-centered has-text-grey" v-text="$t(text)"></div>
    </div>
    <div v-else class="pa-12 has-margin-auto">
      <img src="/images/misc/three-dots.svg" class="is-96x96" />
      <div
        class="text-center has-text-weight-bold grey--text"
        v-text="$t('Please wait. Loading data ...')"
      ></div>
    </div>
  </div>
</template>
<script>
import VueIconBase from '@/components/Commons/VueIconBase'
import { uniCloudInfo } from '@/helpers/icons'
export default {
  icons: {
    uniCloudInfo
  },
  components: {
    VueIconBase
  },
  props: {
    text: {
      type: String,
      default: 'No data available at the moment'
    },
    loading: {
      type: Boolean,
      default: false
    },
    height: {
      type: [Number, String],
      default: 200
    }
  }
}
</script>
