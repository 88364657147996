<template>
  <div>
    <div :class="{ 'is-active': notification }" class="modal">
      <div class="modal-background"></div>
      <div class="card">
        <img
          v-if="screen === 'mobile'"
          class="is-full-width object-fit-cover max-height-12rem"
          :src="notificationInfo.image_mobile"
          alt="notification_image"
        />
        <img
          v-else
          class="is-full-width object-fit-cover max-height-25rem"
          :src="notificationInfo.image_web"
          alt="notification_image"
        />
        <button
          class="modal-close is-large"
          @click="notification = false"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    page: {
      type: String
    }
  },
  data() {
    return {
      anInterval: '',
      notificationInfo: [],
      notification: false
    }
  },
  computed: {
    ...mapGetters({ screen: 'getScreen' })
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.anInterval = setTimeout(() => {
        const homepage = this.page === 'indexPage' ? 'homepage' : ''
        this.$axios
          .$get(
            `api/v1/control-panel/popup-notification/get-popup/?page=${homepage}`
          )
          .then(response => {
            this.notificationInfo = response
            if (Object.keys(this.notificationInfo).length !== 0) {
              this.notification = true
              this.$emit('notification', this.notification)
            }
          })
      }, 1000)
    }
  }
}
</script>
