<template>
  <div class="columns is-multiline">
    <div
      v-if="showLocation"
      class="column is-12 is-relative"
      :style="screen === 'mobile' ? 'padding: 0px 16px' : 'padding: 0 80px'"
    >
      <filter-by-location
        v-model="locations"
        :categories="categories"
        :job-search-page="jobSearchPage"
      ></filter-by-location>
    </div>
    <div
      v-else
      class="column is-12 is-relative"
      :style="screen === 'mobile' ? 'padding: 0px 16px' : 'padding: 0 80px'"
    >
      <filter-by-category
        v-model="categories"
        :locations="locations"
        :job-search-page="jobSearchPage"
      ></filter-by-category>
    </div>
  </div>
</template>
<script>
import FilterByLocation from '@/components/Job/FilterByLocation'
import FilterByCategory from '@/components/Job/FilterByCategory'
import { mapGetters } from 'vuex'
export default {
  name: 'HomePageFilter',
  components: {
    FilterByLocation,
    FilterByCategory
  },
  props: {
    showLocation: {
      type: Boolean,
      default: false
    },
    jobSearchPage: {
      type: Boolean,
      default: false
    },
    locationsProp: {
      type: [Array, Number, String],
      default: () => []
    },
    categoriesProp: {
      type: [Array, Number, String],
      default: () => []
    }
  },

  data() {
    let locations = this.locationsProp
    let categories = this.categoriesProp
    if (this.locationsProp.length) {
      locations = this.locationsProp.toString().split(',')
    }
    if (this.categoriesProp.length) {
      categories = this.categoriesProp.toString().split(',')
    }
    return {
      locations,
      categories
    }
  },
  computed: {
    ...mapGetters({
      screen: 'getScreen'
    })
  },
  watch: {
    locationsProp(val) {
      this.locations = val
    },
    categoriesProp(val) {
      this.categories = val
    },
    locations(val) {
      this.$emit('location', val)
    },
    categories(val) {
      this.$emit('category', val)
    }
  }
}
</script>
