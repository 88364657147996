<template>
  <vue-carousel
    :key="`location-carousel${carouselKey}`"
    :options="{
      perPage: {
        0: 2,
        800: 4, // 2 items for viewport wider than 800px
        1240: 6 // 3 items for viewport wider than 1240px
      }
    }"
    :show-botton="showCarousel"
  >
    <template slot="buttonsColumn">
      <div class="columns is-vcentered has-margin-top-20-mobile">
        <div
          class="is-flex d-placement has-margin-top-5 is-multiline"
          :class="showCarousel ? 'is-8' : 'is-9'"
        >
          <div
            v-for="(item, index) in selectedItemsList"
            :key="`location-${item.id}`"
            class="tag is-light has-margin-top-5 has-margin-right-10 box is-bottom-marginless has-padding-top-10 has-padding-bottom-10 column is-narrow"
          >
            {{ item.name }}
            <button
              class="delete is-small"
              @click="removeSelectedItems(index)"
            ></button>
          </div>
          <span
            v-show="selectedItems.length"
            class="column is-narrow is-text is-rounded pointer is-size-5 has-padding-right-10 has-padding-left-10 has-padding-top-5"
            @click="
              ;(showCarousel = !showCarousel), (carouselKey = carouselKey + 1)
            "
          >
            <vue-icon-base
              height="16"
              width="16"
              :path="$options.icons[$kc('uni-edit-alt')]"
              icon-color="red"
            >
            </vue-icon-base>
          </span>
        </div>
      </div>
    </template>
    <template v-if="showCarousel" slot="carousel">
      <div
        class="has-margin-top-5 has-text-left pointer has-margin-top-5 has-margin-bottom-15"
        @click="selectItem('any')"
      >
        <div
          :style="{ backgroundImage: `url('/images/locations-any.png')` }"
          class="background-image background-image-icon"
        >
          <div
            class="pa-2 fill-height is-flex is-flex-direction-column content-end black-gradient border-16px"
          >
            <div class="is-size-6 has-text-white" v-text="$t('anyWhere')"></div>
            <div
              :title="`${
                homePageFilterFor === 'job'
                  ? $t('jobs here', [
                      get(
                        $store.state.collection.collectionResponses,
                        'homePageJobs.location_total'
                      ) || 0
                    ])
                  : $t('jobseeker here', [
                      get(
                        $store.state.collection.collectionResponses,
                        'homePageJobs.location_total'
                      ) || 0
                    ])
              } `"
              class="has-text-white is-size-7 text-ellipsis"
            >
              {{
                `${
                  homePageFilterFor === 'job'
                    ? $t('jobs here', [
                        get(
                          $store.state.collection.collectionResponses,
                          'homePageJobs.location_total'
                        ) || 0
                      ])
                    : $t('jobseeker here', [
                        get(
                          $store.state.collection.collectionResponses,
                          'homePageJobs.location_total'
                        ) || 0
                      ])
                } `
              }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="(item, index) in items"
        :key="`location-carousel-${index}`"
        class="has-margin-top-5 has-text-left pointer has-margin-top-5 has-margin-bottom-15 has-margin-left-15"
        @click="selectItem(item.id)"
      >
        <div
          :style="{
            backgroundImage: `url(${item.image || '/images/locations-any.png'})`
          }"
          class="background-image background-image-icon"
        >
          <div
            class="pa-2 fill-height is-flex is-flex-direction-column content-end black-gradient border-16px"
          >
            <div
              class="is-size-6 text-ellipsis has-text-white"
              :title="item.name"
              v-text="item.name"
            ></div>
            <div
              :title="`${
                homePageFilterFor === 'job'
                  ? $t('jobs here', [item.count])
                  : $t('jobseeker here', [item.count])
              } `"
              class="has-text-white is-size-7 text-ellipsis"
            >
              {{
                `${
                  homePageFilterFor === 'job'
                    ? $t('jobs here', [item.count])
                    : $t('jobseeker here', [item.count])
                } `
              }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </vue-carousel>
</template>

<script>
import VueCarousel from '@/components/Commons/VueCarousel'
import { truncateChars } from '@/helpers/utils/common'
import VueIconBase from '@/components/Commons/VueIconBase'

import { uniEditAlt } from '@/helpers/icons'
import { mapGetters } from 'vuex'

export default {
  icons: {
    uniEditAlt
  },
  contentUrl: '/api/v1/job/jobs-by-location/',
  components: { VueCarousel, VueIconBase },
  props: {
    value: {
      type: [Number, Array, Object, String],
      default() {
        return []
      }
    },
    jobSearchPage: {
      type: Boolean,
      default: false
    },
    categories: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      selectedItems: this.value || [],
      carouselKey: 1,
      showCarousel: !this.value.length,
      selectedItemsList: []
    }
  },
  computed: {
    items() {
      return (
        this.get(
          this.$store.state.collection.collectionResponses,
          'homePageJobs.locations'
        ) || []
      )
    },
    homePageFilterFor() {
      return this.$store.state.collection.homePageFilterFor
    },
    itemClickRouteName() {
      if (this.homePageFilterFor === 'job') {
        return 'job-search'
      } else if (this.homePageFilterFor === 'job-seeker') {
        return 'jobseeker-search'
      }
      return []
    },
    label() {
      if (this.homePageFilterFor === 'job') {
        this.$t('whereJob')
      } else if (this.homePageFilterFor === 'job-seeker') {
        return this.$t('wherejobseeker')
      }
      return this.$t('whereJob')
    },
    ...mapGetters({ screen: 'getScreen' })
  },
  watch: {
    items: {
      handler(newval) {
        this.carouselKey = this.carouselKey + 1
      },
      deep: true
    },
    selectedItems(val) {
      if (!this.jobSearchPage) {
        this.$router.push(
          this.localePath({
            name: this.itemClickRouteName,
            query: { locations: val }
          })
        )
        return
      }
      if (val.includes('any') && val.length > 1) {
        this.selectedItems.splice(this.selectedItems.indexOf('any'), 1)
        return
      }
      this.$emit('input', val)
      this.showCarousel = !val.length
    },
    categories(val) {
      if (!val.includes('any')) {
        this.getData()
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    selectItem(val) {
      if (!this.jobSearchPage) {
        this.$router.push(
          this.localePath({
            name: this.itemClickRouteName,
            query: { locations: val }
          })
        )
        return
      }
      if (val === 'any') {
        this.selectedItems = ['any']
      } else if (this.selectedItems.includes('any')) {
        this.selectedItems.splice(this.selectedItems.indexOf('any'), 1)
      }

      if (!this.selectedItems.includes(val)) {
        this.selectedItems.push(val)
      }
    },
    showSnackBar() {
      this.notifyError(this.$t('Please select from the list'))
    },
    async getData() {
      let contentUrl = '/api/v1/job/jobs-by-locations/'
      if (this.homePageFilterFor === 'job') {
        contentUrl = `/api/v1/job/jobs-by-locations/?categories=${this.categories}`
      } else if (this.homePageFilterFor === 'job-seeker') {
        contentUrl = `/api/v1/jobseekers/by-location/?categories=${this.categories}`
      }
      const { data } = await this.$axios.get(contentUrl)
      data.location_total = data.total || 0
      this.$store.commit('collection/setCollectionData', ['homePageJobs', data])
    },
    truncateChars,
    updateSelectedItem(val) {
      this.selectedItemsList = val
    },
    removeSelectedItems(index) {
      this.selectedItems.splice(index, 1)
    }
  }
}
</script>
<style scoped>
.card {
  border-radius: 4px;
}
.border-16px {
  border-radius: 16px;
}
.background-image {
  height: 15rem;
  width: 100%;
  border-radius: 16px;
}
.background-image-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.black-gradient {
  background: linear-gradient(
    179deg,
    transparent,
    rgb(255 124 58 / 15%) 65%,
    rgb(0 0 0 / 85%)
  );
}
</style>
