var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-carousel',{key:("location-carousel" + _vm.carouselKey),attrs:{"options":{
    perPage: {
      0: 2,
      800: 4, // 2 items for viewport wider than 800px
      1240: 6 // 3 items for viewport wider than 1240px
    }
  },"show-botton":_vm.showCarousel}},[_c('template',{slot:"buttonsColumn"},[_c('div',{staticClass:"columns is-vcentered has-margin-top-20-mobile"},[_c('div',{staticClass:"is-flex d-placement has-margin-top-5 is-multiline",class:_vm.showCarousel ? 'is-8' : 'is-9'},[_vm._l((_vm.selectedItemsList),function(item,index){return _c('div',{key:("location-" + (item.id)),staticClass:"tag is-light has-margin-top-5 has-margin-right-10 box is-bottom-marginless has-padding-top-10 has-padding-bottom-10 column is-narrow"},[_vm._v("\n          "+_vm._s(item.name)+"\n          "),_c('button',{staticClass:"delete is-small",on:{"click":function($event){return _vm.removeSelectedItems(index)}}})])}),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedItems.length),expression:"selectedItems.length"}],staticClass:"column is-narrow is-text is-rounded pointer is-size-5 has-padding-right-10 has-padding-left-10 has-padding-top-5",on:{"click":function($event){;(_vm.showCarousel = !_vm.showCarousel), (_vm.carouselKey = _vm.carouselKey + 1)}}},[_c('vue-icon-base',{attrs:{"height":"16","width":"16","path":_vm.$options.icons[_vm.$kc('uni-edit-alt')],"icon-color":"red"}})],1)],2)])]),_vm._v(" "),(_vm.showCarousel)?_c('template',{slot:"carousel"},[_c('div',{staticClass:"has-margin-top-5 has-text-left pointer has-margin-top-5 has-margin-bottom-15",on:{"click":function($event){return _vm.selectItem('any')}}},[_c('div',{staticClass:"background-image background-image-icon",style:({ backgroundImage: "url('/images/locations-any.png')" })},[_c('div',{staticClass:"pa-2 fill-height is-flex is-flex-direction-column content-end black-gradient border-16px"},[_c('div',{staticClass:"is-size-6 has-text-white",domProps:{"textContent":_vm._s(_vm.$t('anyWhere'))}}),_vm._v(" "),_c('div',{staticClass:"has-text-white is-size-7 text-ellipsis",attrs:{"title":((_vm.homePageFilterFor === 'job'
                ? _vm.$t('jobs here', [
                    _vm.get(
                      _vm.$store.state.collection.collectionResponses,
                      'homePageJobs.location_total'
                    ) || 0
                  ])
                : _vm.$t('jobseeker here', [
                    _vm.get(
                      _vm.$store.state.collection.collectionResponses,
                      'homePageJobs.location_total'
                    ) || 0
                  ])) + " ")}},[_vm._v("\n            "+_vm._s(((_vm.homePageFilterFor === 'job'
                  ? _vm.$t('jobs here', [
                      _vm.get(
                        _vm.$store.state.collection.collectionResponses,
                        'homePageJobs.location_total'
                      ) || 0
                    ])
                  : _vm.$t('jobseeker here', [
                      _vm.get(
                        _vm.$store.state.collection.collectionResponses,
                        'homePageJobs.location_total'
                      ) || 0
                    ])) + " "))+"\n          ")])])])]),_vm._v(" "),_vm._l((_vm.items),function(item,index){return _c('div',{key:("location-carousel-" + index),staticClass:"has-margin-top-5 has-text-left pointer has-margin-top-5 has-margin-bottom-15 has-margin-left-15",on:{"click":function($event){return _vm.selectItem(item.id)}}},[_c('div',{staticClass:"background-image background-image-icon",style:({
          backgroundImage: ("url(" + (item.image || '/images/locations-any.png') + ")")
        })},[_c('div',{staticClass:"pa-2 fill-height is-flex is-flex-direction-column content-end black-gradient border-16px"},[_c('div',{staticClass:"is-size-6 text-ellipsis has-text-white",attrs:{"title":item.name},domProps:{"textContent":_vm._s(item.name)}}),_vm._v(" "),_c('div',{staticClass:"has-text-white is-size-7 text-ellipsis",attrs:{"title":((_vm.homePageFilterFor === 'job'
                ? _vm.$t('jobs here', [item.count])
                : _vm.$t('jobseeker here', [item.count])) + " ")}},[_vm._v("\n            "+_vm._s(((_vm.homePageFilterFor === 'job'
                  ? _vm.$t('jobs here', [item.count])
                  : _vm.$t('jobseeker here', [item.count])) + " "))+"\n          ")])])])])})],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }